/* === BENEFITS === */

#benefits{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#benefits li{
    font-size: 1.20em;
    list-style: none;
    line-height: 1.75em;
    color: var(--off-white);
}

#benefits ul{
    padding: 0;
}

@media (max-width: 1000px){
    #benefits{
        margin: 50px 0;
        height: auto;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }

    #benefits h2{
        margin-bottom: 50px;
    }
}

/* === SERVICES === */

#services h2{
    margin-bottom: 20px;
}

.service{
    margin: 20px 0;
}

@media (max-width:1000px){
    #services{
        margin: 50px 0;
        height: auto;
    }
}

/* === PROGRAMMING LANGUAGES === */

.programming{
    margin: 40px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;
}

.programming-display{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fa-shopify{
    background: linear-gradient(45deg, #96bf48, #5e8e3e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-wix{
    background: linear-gradient(45deg, #ffda44, #fdb813);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-magento{
    background: linear-gradient(45deg, #F46D25, #D35400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-squarespace {
    background: linear-gradient(45deg, #222222, #333333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-wordpress{
    background: linear-gradient(45deg, #21759b, #2c9cc0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-elementor{
    background: linear-gradient(45deg, #d84a7b, #833fa7);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

@media (max-width:1000px){
    .programming{
        flex-direction: column;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .programming-display{
        margin: 30px 0;
    }
}

