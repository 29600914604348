.contact-main{
    display: flex;
    justify-content: center;
}

#contact-form-section {
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 600px;
    padding: 50px 20px;
    background-color: var(--slate-gray);
    border-radius: 5px;
}

#contact-form-section h1{
    color: var(--off-white);
}

#contact-form-section p{
    color: var(--off-white);
}

.form-group {
    margin: 15px 0;
}

label {
    display: block;
    margin-bottom: 5px;
    color: var(--off-white);
}

input[type="text"],
input[type="email"],
select,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button{
    display: block;
    margin: 0 auto;
}

@media (max-width:600px){
    main{
        padding: 90px 0;
    }
}