#guided-services-display{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: auto;
    padding: 50px;

}

.recommendation-display{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.guided-services{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    height: 40vh;
    margin: 50px 0;
    overflow: hidden;
    color: white;
}

.guided-services h3{
    color: var(--off-white);
}

.guided-services p{
    transform: translateY(100%);
    filter: blur(2px);
    transition: all 0.5s ease;
    opacity: 0;
    padding: 0 20px;
}

.guided-services:hover p{
    transform: translateY(0);
    filter: blur(0);
    display: block;
    opacity: 1;
}

.guided-services img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(70%);
    transition: all 0.5s ease;
}

.guided-services:hover img{
    transform: scale(1.3);
    filter: brightness(50%);
}

@media (max-width: 1000px){

    .recommendation-display{
        grid-template-columns: 1fr 1fr;
    }

    #guided-services-display{
        align-items: center;
    }

    .guided-services{
        width: 100%;
        padding: 0 30px;
    }

    .guided-services p{
        opacity: 1;
        transform: translateY(0);
        filter: blur(0);
    }
}

@media (max-width: 600px){
    .recommendation-display{
        grid-template-columns: 1fr;
    }
}