button {
    background-color: var(--vibrant-coral);
    color: var(--off-white);
    border: 2px solid var(--vibrant-coral);
    padding: 10px 20px;
    font-size: .5em;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  }
  
  button:hover{
    background-color: var(--off-white);
    color: var(--vibrant-coral);
    border-color: var(--vibrant-coral);
    text-decoration: none;
  }
  
  button:active{
    background-color: var(--slate-gray);
    color: var(--off-white);
    border-color: var(--slate-gray);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  button:focus{
    outline: none;
    box-shadow: 0 0 0 4px rgba(191, 140, 128, 0.5);
  }