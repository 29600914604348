.unique-section{
    display: flex;
    width: 100%;
    background-color: var(--slate-gray);
    color: var(--off-white);
  }
  
  .unique-heading {
    width: 50%;
    position: sticky;
    padding: 20px 50px;
    top: 100px; /* You may want to make this a prop too if it needs to vary */
    align-self: flex-start;
  }
  
  .unique-section h2, .unique-section h3 {
    color: var(--off-white);
  }
  
  .unique-section h2 {
    font-size: 1em;
  }
  
  .unique-section h3 {
    font-size: 2em;
  }
  
  .unique-section article {
    padding: 0 90px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .unique-section article div {
    padding: 40px 0;
  }
  
  .unique-section article i {
    font-size: 3em;
  }
  
  .unique-section article p {
    font-size: 1.25em;
  }

  @media (max-width: 1000px){
    .unique-section{
      flex-direction: column;
      align-items: center;
      padding: 50px 20px;
    }

    .unique-heading{
      width: 100%;
      position: relative;
      top: 0;
      padding: 0;
    }

    .unique-section article{
      width: 100%;
      padding: 20px 0;
    }

    .unique-section article div{
      width: 100%;
    }
  }
  