.erp-main li{
    color: #000;
}

h2{
    margin-bottom: 30px;
}

#why-oracle{
    width: 100%;
}

.oracle-intro{
    display: flex;
}

.oracle-intro p{
    width: 50%;
    padding: 40px;
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 50%;
    background: #000;
}

.video-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.cta a{
    color: var(--vibrant-coral);
    text-decoration: underline;
}

.cta a:hover{
    text-decoration: underline;
}

@media (max-width: 1280px){
    .oracle-intro{
        flex-direction: column;
        width: 100%;
    }

    .oracle-intro p{
        width: 100%;
        padding: 0;
    }

    .video-container{
        left: 0;
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
    }
}