/* === BENEFITS === */

#benefits{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#benefits ul{
    padding: 0;
}

#benefits li{
    font-size: 1.20em;
    list-style: none;
    line-height: 1.75em;
    color: var(--off-white);
}

@media (max-width: 1000px){
    #benefits{
        margin: 50px 0;
        height: auto;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }

    #benefits h2{
        margin-bottom: 50px;
    }
}

/* === SERVICES === */

#services h2{
    margin-bottom: 20px;
}

.service{
    margin: 20px 0;
}

@media (max-width:1000px){
    #services{
        margin: 50px 0;
        height: auto;
    }
}

/* === PROGRAMMING LANGUAGES === */

.programming{
    margin: 40px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.programming-display{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fa-html5{
    background: linear-gradient(45deg, #e44d26, #f16529);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-css3-alt{
    background: linear-gradient(45deg, #264de4, #2965f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-js {
    background: linear-gradient(45deg, #f7df1e, #e4a017);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-react {
    background: linear-gradient(45deg, #61dafb, #21a1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-angular {
    background: linear-gradient(45deg, #dd1b16, #c3002f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-python {
    background: linear-gradient(45deg, #306998, #ffe873);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-java {
    background: linear-gradient(45deg, #5382a1, #2a6474);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

.fa-database {
    background: linear-gradient(45deg, #f29111, #d49424);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 5em;
}

@media (max-width:1000px){
    .programming{
        flex-direction: column;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .programming-display{
        margin: 30px 0;
    }
}

/* === THE PROCESS === */
  
  .process-display-webdesign{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;

  }
  
  .process-items-webdesign{
      border: 1px var(--off-white) solid;
      border-radius: 20px;
      padding: 20px 10px;
  }
  
  .process-items i{
      font-size: 4em;
      font-weight: 700;
      position: absolute;
      right: 30px;
      top: -50px;
      color: var(--vibrant-coral);

  
  }
  
  @media only screen and (max-width: 1280px){

    .process-display-webdesign{
        grid-template-columns: 1fr 1fr;
    }

      #process{
         height: auto; 
      }
  
      .process-display{
          flex-direction: column;
          align-items: center;
      }
  
      .process-items{
          width: 50%;
          margin: 40px 0;
      }
  }
  
  @media (max-width: 600px){

    .process-display-webdesign{
        grid-template-columns: 1fr;
    }

      #process{
          margin: 50px 0;
      }
  
      .process-items{
          width: 100%;
          margin: 80px 0;
      }
  }

/* INTRODUCTION */

#introduction-webdesign section{
    display: flex;
    flex-direction: row-reverse;
  }
  
#introduction-webdesign article{
    width: 50%;
    padding: 0 40px;
  }
  
#introduction-webdesign article div{
    display: flex;
    justify-content: space-evenly;
  }
  
  #introduction-webdesign article div a{
    color: var(--deep-blue);
  }
  
  #introduction-webdesign article div a:hover{
    color: var(--vibrant-coral);
  }
  
  #introduction-webdesign h2{
    font-size: 1em;
    color: var(--slate-gray);
  }
  
  #introduction-webdesign h3{
    font-size: 2em;
  }
  
  #introduction-webdesign img{
    width: 50%;
    padding: 0 40px;
  }
  
  @media (max-width: 1280px){
    #introduction-webdesign{
      width: 100%;
    }
  
    #introduction-webdesign section{
      flex-direction: column-reverse;
    }
  
    #introduction-webdesign img{
      width: 100%;
      overflow: hidden;
    }
  
    #introduction-webdesign article{
      width: 100%;
    }
  }