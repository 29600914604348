header{
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  height: 80px;
  background-color: var(--deep-blue);
  z-index: 100;
  font-size: 1.20em;
}

header nav{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.logo{
  height: 40px;
}

.main-menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.menu-items{
  padding: 20px 20px;
}

@media (min-width: 1281px) {
  #menu-bars, #menu-close {
      display: none !important; /* Ensures that icons are not shown on larger screens */
  }
}


@media (max-width:1280px){
  .nav-icons{
      display: block;
      color: var(--off-white);
      font-size: 1.25em;
  }

  #mainMenu{
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    z-index: 1000;
    background-color: var(--deep-blue);
    overflow: auto;
    transform: translateX(100%);
    transition: .3s ease-in;
  }

  #mainMenu.open {
    transform: translateX(0);
  }

  .menu-items{
    padding: 0;
  }

  .nav-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 50px;
    border-bottom: 1px solid var(--off-white);
    padding: 50px 0;
    padding-left: 30px;
    z-index: 10;
  }

  #contact-button{
      position: relative;
      bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 50px 0;
  }

  #menu-bars{
      display: block;
  }

  #menu-close{
      display: none;
  }
}
  