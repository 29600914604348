a{
    text-decoration: none;
    color: var(--off-white);
    transition: 0.3s ease-in;
    cursor:pointer;

}

a:hover{
    color: var(--vibrant-coral);
}