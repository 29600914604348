/* === WHO WE ARE === */

#who-we-are{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: auto;
}

#who-we-are article{
    display: flex;
}

#who-we-are article p{
    width: 70%;
    padding: 30px;
}

#who-we-are article h2{
    font-size: 2.5em;
    padding: 30px;
    width: 30%;
}

@media (max-width: 700px){
    #who-we-are article{
        flex-direction: column;
        align-items: center;
    }

    #who-we-are article h2{
        display: flex;
        justify-content: center;
        font-size: 2em;
        width: 100%;
    }

    #who-we-are article p{
        width: 100%;
        padding: 0;
    }
}

/* === COMMITMENTS === */

#commitments-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px;
    height: auto;
}

#commitments-section h2{
    margin: 50px 0;
}

.commitments-display{
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
}

.commitments{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.commitments i{
    font-size: 2em;
    margin-right: 10px;
    color: var(--vibrant-coral);
}

@media (max-width:1200px){
    .commitments{
        padding: 20px 10px;
    }
} 

@media (max-width: 700px){
    .commitments-display{
        flex-wrap: nowrap;
        flex-direction: column;
    }
}



/* === OUR VALUES === */

#our-values{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: 50px 0;
}

#our-values h2{
    margin: 20px 0;
}

#our-values p {
    padding: 0 20px;
}

.values-display{
    display: flex;
    width: 100%;
    margin: 40px 0;
    align-items: flex-start;
    justify-content: center;
}

.values-display article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 33%;
    padding: 0;
    height: auto;
}

.values-display article span{
    color: var(--vibrant-coral);
    font-size: 4em;
    font-weight: 700;
    margin: 20px 0;
}

@media (max-width: 1100px){
    .values-display{
        flex-direction: column;
    }

    .values-display article{
        width: 100%;
    }

    #our-values p{
        padding: 0;
    }
}