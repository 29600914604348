/* === SERVICES === */

#services-overview{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
}

#services-overview h2{
    margin-bottom: 20px;
}

.services-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.services-list h3{
    color: var(--off-white);
}

.services-list a{
    margin: 50px 0;
}

.services-list a:hover{
    color: var(--off-white);
}

.services-list article{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    height: 40vh;
    overflow: hidden;
    padding: 0 40px;
}

.services-list article p{
    transform: translateY(100%);
    filter: blur(2px);
    transition: all 0.5s ease;
    opacity: 0;
}

.services-list article:hover p{
    transform: translateY(0);
    filter: blur(0);
    display: block;
    opacity: 1;
}

.services-list img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(50%);
    transition: all 0.5s ease;
}

.services-list article:hover img{
    transform: scale(1.3);
    filter: brightness(30%);
}

@media (max-width: 1280px){
    #services-overview{
        align-items: center;
        justify-content: center;
    }

    .services-list{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .services-list article p{
        opacity: 1;
        transform: translateY(0);
        filter: blur(0);
    }

}

@media (max-width: 850px){
    .services-list{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 650px){
    .services-list{
        grid-template-columns: 1fr;
    }
}

/* === WHY CHOOSE US === */

#why-choose-us h2{
    margin-bottom: 20px;
}

#why-choose-us p{
    margin-bottom: 20px;
}

#why-choose-us li{
    color: var(--deep-blue);
}