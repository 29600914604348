.privacy-policy-main {
    padding: 20px;
    line-height: 1.6;
    font-family: 'Quattrocento', sans-serif;
}

.privacy-policy-main h1,
.privacy-policy-main h2 {
    font-family: 'Oswald', sans-serif;
    margin-bottom: 10px;
    color: var(--deep-blue);
}

.privacy-policy-main h2 {
    margin-top: 20px;
}

.privacy-policy-main h3 {
    margin-top: 15px;
    margin-bottom: 10px;
}

.privacy-policy-main p {
    margin-bottom: 15px;
}

.privacy-policy-main ul {
    margin-bottom: 20px;
    padding-left: 20px;
}

.privacy-policy-main ul li {
    margin-bottom: 10px;
    color: var(--deep-blue);
}

.privacy-policy-main address {
    font-style: normal;
    line-height: 1.8;
    color: var(--deep-blue);
}

.privacy-policy-main a {
    color: #007bff;
    text-decoration: none;
}

.privacy-policy-main a:hover {
    text-decoration: underline;
}