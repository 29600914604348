:root {
  --deep-blue: #111C26;
  --slate-gray: #61788C;
  --sand: #D9D1B8;
  --vibrant-coral: #E57373;
  --off-white: #F2F2F2;
}

*{
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--off-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: var(--deep-blue);
}

p, a, q, li {
  font-family: "Quattrocento", serif;
  line-height: 25px;
  font-weight: 600;
  font-size: 1em;
}

@media (max-width: 600px){
  p, a, q, li{
    font-weight: 800;
  }
}

b {
  font-family: "Quattrocento", serif;
  line-height: 25px;
  font-weight: 900;
  color: var(--vibrant-coral);
}
