/* src/CoreTeam.css */
.core-team {
    padding: 80px 30px;
    text-align: center;
  }
  
  .core-team h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .core-team p {
    max-width: 700px;
    margin: 0 auto 20px auto;
    font-size: 1.1rem;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .team-member {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .team-photo {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .team-member h2 {
    font-size: 1.5rem;
    margin: 10px 0 5px;
  }
  
  .team-member h3 {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .team-member p {
    font-size: 1rem;
    color: #333;
  }
  
  