footer{
    padding: 50px 40px 10px 40px;
    background-color: var(--deep-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer section{
    width: 100%;
}

.footer-nav-links{
    display: flex;
    justify-content: space-between;
    padding: 0 150px;
    color: var(--off-white);
}

.footer-nav-links h3{
    color: var(--off-white);
}

.footer-nav-links-design{
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.footer-nav-social i{
    margin-top: 30px;
    padding: 0 5px;
    font-size: 1.5em;
}

.agency-description{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px var(--off-white) solid;
    color: var(--off-white);
    margin: 10px 80px;
    padding: 30px 0;
}

.footer-legal{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--off-white);
}

.footer-legal a{
    border-left: 1px solid var(--off-white);
    padding: 0 10px;
}

.footer-legal p{
    padding: 0 10px;
}

@media (max-width:1000px){

    .footer-nav-links{
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .footer-nav-links h3{
        font-size: 2em;
    }

    .footer-nav-links div{
        width: 100%;
        text-align: center;
        margin: 30px 0;
    }

    .footer-nav-links-design{
        margin-top: 20px;
    }

    .footer-nav-links-design a li{
        padding: 10px 0;
        font-size: 1.25em;
    }

    .agency-description{
        margin: 10px 20px;
    }

    .footer-legal{
        padding: 20px 10px;
    }
}