#cta{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: var(--off-white);
    height: auto;
}

#cta p{
  margin: 20px 0;
  color: var(--deep-blue);
}