#hero{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  color: var(--off-white);
  height: 100vh;
}

#hero section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: var(--slate-gray);
  height: 100%;
  width: 40%;
  padding: 0 60px;
}

#hero h1{
  font-size: 3em;
  color: var(--off-white);
}

#hero p{
  font-size: 2em;
  line-height: 2rem;
  color: var(--off-white);
}
 
#hero img{
  height: 100%;
  width: 60%;
  z-index: -1;
  object-fit:cover;
  filter: brightness(90%);
}

@media (max-width: 1280px){
  #hero{
    height: auto;
    width: 100%;
    position: relative;
    top: 80px;
  }

  #hero section{
    width: 100%;
    padding: 60px;
  }
  
  #hero img{
    display: none;
  }
}

@media (max-width: 600px){
  #hero{
    position: relative;
    top: -10px;
  }

  #hero h1{
    font-size: 2em;
  }

  #hero p{
    font-size: 1.25em;
  }
}