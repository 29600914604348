

#seo{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#seo h2{
    margin: 40px 0;
}

#seo article{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

#seo article{
    width: 100%;
}

#seo article p{
    padding: 0 40px;
}

.seo-network{
    width: 50%;
    border-radius: 10px;
}



@media (max-width: 700px){
    #seo article{
        flex-direction: column;
        align-items: center;
    }

    .seo-network{
        margin: 40px 0;
        width: 100%;
        padding: 0 20px;
    }
}
/* === ADVANTAGES === */

#advantages{
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    margin: 50px 0;
    height: auto;
    width: 100%;
}

.quote-display{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0 20px;
}

.advantage-img{
    height: auto;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.advantage-display{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.advantage{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    width: 100%;
}
.advantage i{
    color: var(--vibrant-coral);
    font-size: 2em;
}

.advantage p {
    font-size: 1.25em;
}

@media (max-width:1200px){
    #advantages{
        flex-direction: column-reverse;
    }

    .advantage-display{
        width: 100%;
    }

    .quote-display{
        width: 100%;
    }

    .advantage{
        padding: 20px 10px;
    }
} 

@media (max-width: 700px){
    #advantages{
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
    }
}

/* === THE PROCESS === */

#process{
    display: flex;
    flex-direction: column;
    justify-content: space-around;  
    padding: 0 50px;
    height: 80vh;
}
  
.process-display{
    margin: 50px 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      align-items: start;
}
  
  .process-items{
      position: relative;
      border: 1px var(--off-white) solid;
      border-radius: 20px;
      width: 100%;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  }
  
  .process-items i{
      font-size: 4em;
      font-weight: 700;
      position: absolute;
      right: 30px;
      top: -50px;
      color: var(--deep-blue);
      padding: 0 5px;
  
  }
  
  @media (max-width: 1280px){
      #process{
         height: auto; 
      }
  
      .process-display{
        grid-template-columns: 1fr 1fr;
        align-items: center;
        align-items: start;
        justify-content: center;
      }
  
      .process-items{
          width: 80%;
          margin: 40px auto;
      }
  }
  
  @media (max-width: 600px){
      #process{
          margin: 50px 0;
      }
    
    .process-display{
        grid-template-columns: 1fr;
    }
  
      .process-items{
          width: 100%;
          margin: 80px 0;
      }
  }



.faq-item{
    border-bottom: 1px solid var(--sand);
}

.faq-question {
    cursor: pointer;
    padding: 20px 10px;
    font-size: 18px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
}

.faq-item.active .faq-answer {
    max-height: 500px; /* Set a max-height that is larger than the expected content */
    transition: max-height 0.4s ease;
}

.faq-item.active h3{
    color: var(--vibrant-coral);
}

.faq-answer p {
    margin: 0;
    padding: 20px;
}