.main-sections {
    height: auto;
    padding: 100px 50px;
  }
  
  @media (max-width: 1280px) {
    .main-sections {
      padding: 100px 10px;
    }
  }
  