/* === WHO WE ARE === */

#introduction section{
  display: flex;
}

#introduction article{
  width: 50%;
  padding: 0 40px;
}

#introduction article div{
  display: flex;
  justify-content: space-evenly;
}

#introduction article div a{
  color: var(--vibrant-coral);
  font-size: 1.25em;
  margin: 10px 0;
  text-decoration: underline;
  font-weight: 900;
}

#introduction article div a:hover{
  color: var(--vibrant-coral);
}

#introduction h2{
  font-size: 1em;
  color: var(--slate-gray);
}

#introduction h3{
  font-size: 2em;
}

#introduction img{
  width: 50%;
  padding: 0 40px;
}

@media (max-width: 1280px){
  #introduction{
    width: 100%;
  }

  #introduction section{
    flex-direction: column-reverse;
  }

  #introduction img{
    width: 100%;
    overflow: hidden;
  }

  #introduction article{
    width: 100%;
  }
}

@media (max-width: 600px){
  #introduction article div{
    display: flex;
    flex-direction: column;
  }
}

/* === ARTICLES GRID === */

#services-articles h2{
  font-size: 1em;
  color: var(--slate-gray);
}

#services-articles h3{
  font-size: 2em;
}

.articles-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 40px;
}

.article-item {
    background-color: transparent;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid var(--deep-blue);
    text-align: center;
    transition: transform 0.3s ease;
}

.article-item:hover {
    transform: translateY(-5px);
}

.article-item i {
    font-size: 40px;
    color: var(--deep-blue);
    margin-bottom: 15px;
}

.article-item h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.article-item p {
    font-size: 16px;
    margin-bottom: 20px;
}

.article-button {
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    border: 1px solid var(--off-white);
    background-color: var(--vibrant-coral);
    border-radius: 8px;
}

.article-button:hover{
    background-color: var(--off-white);
    color: var(--vibrant-coral);
    border-color: var(--vibrant-coral);
}

.article-button:hover i{
    color: var(--vibrant-coral);
}

.article-button:active{
    background-color: var(--slate-gray);
    color: var(--deep-blue);
    border-color: var(--slate-gray);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.article-button:focus{
    outline: none;
    box-shadow: 0 0 0 4px rgba(191, 140, 128, 0.5);
}

.article-button i {
    padding: 5px 30px;
    margin: 0;
}

@media (max-width: 1280px) {
    .articles-grid {
        grid-template-columns: 1fr;
    }
}

/* === UNIQUE === */




/* === PARTNERS === */

#partners{
    padding: 0;
    margin: 100px 0;
}

#partners h2{
    padding: 0 50px;
    margin-bottom: 20px;
}

@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;

    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;

  }
  
  .logos:after {
    right: 0;
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 10s slide infinite linear;
  }
  
  .logos-slide img {
    height: 50px;
    margin: 0 40px;
  }