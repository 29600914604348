.how-we-work {   
    max-width: 800px;
    margin: auto;
  }
  
  .how-we-work h1 {
    color: #333;
    text-align: center;
  }
  
  .how-we-work p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
  }
  
  .how-we-work ol {
    margin: 30px 0;
    padding-left: 20px;
  }
  
  .how-we-work li {
    margin-bottom: 30px;
    font-size: 1em;
  }
  
